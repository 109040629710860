@import url(https://use.fontawesome.com/releases/v5.8.1/css/all.css);
html,
body,
/* #root {
  height: 100%;
} */

body {
  margin: 0;
}
.tooltip-inner {
  background: white;
  color: black;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  padding: 10px;
  z-index: 999999 !important;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
span.arrow::before {
  border-right-color: white !important;
}
.btn-secondary.focus,
.btn-secondary:focus {
  background: rgb(226, 107, 71);
  border-color: rgb(226, 107, 71);
}

.show > .btn-secondary.dropdown-toggle {
  color: black !important;
}

.rc-steps-item-process .rc-steps-item-icon {
  background: rgb(226, 107, 71);
  border-color: rgb(226, 107, 71);
}

.rc-steps-item.rc-steps-item-finish .rc-steps-item-icon {
  background: rgb(38, 149, 167);
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
  color: white;
  font-weight: 900;
}
.rc-steps-item-description {
  font-size: 14px;
}
.card-header {
  background: #dff0d8;
  color: #3c763d;
}
.DayPicker-Day {
  padding: 0.5em 0.6em !important;
}
@media (max-width: 768px) {
  .rc-steps-item:last-child .rc-steps-item-description {
    padding: 0 !important;
  }
  .rc-steps-item:last-child .rc-steps-item-description .DayPicker {
    display: block !important;
  }
}
.rc-steps-item-container {
  width: 100%;
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  opacity: 0.3;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: rgb(38, 149, 167);
  /* background-color: rgb(226, 107, 71); */
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: rgb(38, 149, 167);
  /* background-color: rgb(226, 107, 71); */
}

.rc-steps-vertical .rc-steps-item-content {
  overflow: visible;
}

.rc-steps-vertical .rc-steps-item-description {
  padding-left: 30px;
}
.rc-steps-item-finish .rc-steps-item-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-secondary {
  color: rgb(226, 107, 71) !important;
}

.css-9gakcf-option .btn-link {
  color: white !important;
}

tr td:last-child {
  white-space: nowrap;
}

.modal-dialog {
  padding-bottom: 200px;
}

.ribbon-wrapper {
  width: 120px;
  height: 127px;
  overflow: hidden;
  position: absolute;
  top: -2px;
  right: -2px;
  z-index: 1;
}
.ribbon {
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Montserrat Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0.05em;
  line-height: 15px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  transform: rotate(45deg);
  position: relative;
  padding: 6px 0px;
  right: 24px;
  top: 38px;
  width: 180px;
  height: 24px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #dedede;
  background-image: linear-gradient(to bottom, #ffffff 45%, #dedede 100%);
  background-repeat: repeat-x;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffdedede', GradientType=0);
}

.ribbon:before,
.ribbon:after {
  content: '';
  border-top: 3px solid #9e9e9e;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  bottom: -3px;
}

.ribbon:before {
  left: 0;
}

.ribbon:after {
  right: 0;
}

.ribbon.green {
  background-color: #2da285;
  background-image: linear-gradient(to bottom, #2da285 45%, #227a64 100%);
  background-repeat: repeat-x;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff2da285', endColorstr='#ff227a64', GradientType=0);
}

.ribbon.green:before,
.ribbon.green:after {
  border-top: 3px solid #113e33;
}

.ribbon.red {
  background-color: #bc1a3a;
  background-image: linear-gradient(to bottom, #a61733 45%, #bc1a3a 100%);
  background-repeat: repeat-x;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffa61733', endColorstr='#ffbc1a3a', GradientType=0);
}

.ribbon.red:before,
.ribbon.red:after {
  border-top: 3px solid #8f142c;
}

.ribbon.blue {
  background-color: #1a8bbc;
  background-image: linear-gradient(to bottom, #177aa6 45%, #1a8bbc 100%);
  background-repeat: repeat-x;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#177aa6', endColorstr='#ff1a8bbc', GradientType=0);
}

.ribbon.blue:before,
.ribbon.blue:after {
  border-top: 3px solid #115979;
}

.ribbon.grey {
  background-color: #808080;
  background-image: linear-gradient(to bottom, #808080 45%, #d29719 100%);
  background-repeat: repeat-x;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#808080', endColorstr='#ffd29719', GradientType=0);
}

.ribbon.grey:before,
.ribbon.grey:after {
  border-top: 3px solid #737373;
}

.ribbon.blueGrey {
  background-color: #536878;
  background-image: linear-gradient(to bottom, #536878 45%, #747b81 100%);
  background-repeat: repeat-x;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#536878', endColorstr='#ff747b81	', GradientType=0);
}

.ribbon.blueGrey:before,
.ribbon.blueGrey:after {
  border-top: 3px solid #4f5357;
}

.ribbon.yellow {
  background-color: #e7d30d;
  background-image: linear-gradient(to bottom, #e7d30d 45%, #d1bf08 100%);
  background-repeat: repeat-x;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#e7d30d', endColorstr='#ffd1bf08', GradientType=0);
}

.ribbon.yellow:before,
.ribbon.yellow:after {
  border-top: 3px solid #d29719;
}

.DayPickerInput-OverlayWrapper,
.css-26l3qy-menu {
  z-index: 2 !important;
}

.global-modal > .modal-content {
  background: transparent !important;
  border: none !important;
}

.modal-backdrop.show {
  opacity: 0.85 !important;
}

.required-form input + label {
  color: #999;
  position: absolute;
  left: 55px;
  top: 7px;
  margin-bottom: 0 !important;
}

.required-form input[required] + label:after {
  content: '*';
  color: #dc3545;
  padding-left: 3px;
}

.required-form input[required]:invalid + label {
  display: inline-block;
  pointer-events: none;
}

.required-form input[required]:valid + label {
  display: none;
}

.required-form .css-1wa3eu0-placeholder:after {
  content: '*';
  color: #dc3545;
  padding-left: 3px;
}

.form-control {
  border-radius: 0.25rem !important;
}

.nav-tabs .nav-link {
  cursor: pointer;
}

